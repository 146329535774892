import React from 'react'

export const Desination = () => {
  return (
    <div>
        <section class="layout-pt-lg layout-pb-lg mt-60">
      <div class="container">
        <div class="row justify-center text-center">
          <div class="col-xl-6 col-lg-8 col-md-10" data-split='lines' data-anim="split-lines delay-1">
            <div class="text-15 uppercase mb-30 sm:mb-10">
              OUR ACCOMMODATIONS
            </div>
            <h2 class="text-40 md:text-30 lh-11">
              Discover Our Resorts,
              Suites & Villas
            </h2>
            
          </div>
        </div>

        <div class="row x-gap-30 y-gap-60 pt-60 sm:pt-50">

          <div class="col-lg-4 col-md-6" data-anim="slide-up delay-1">
            <a href="samudragupt-resort" class="roomCard -type-2 -hover-button-center">
              <div class="roomCard__image -no-rounded ratio ratio-45:54 -hover-button-center__wrap">
                <img src="assets/img/joy/joy-tread-aboutus-2.png" alt="image" class="img-ratio"/>
              </div>

              <div class="roomCard__content mt-30">
                <div class="d-flex justify-between items-end">
                  <h3 class="roomCard__title lh-12 text-20 md:text-18">Samudragupt Lifestyle Retreat, Gholvad Dahanu</h3>
                </div>

                

                <a class="button -md -type-2 -outline-accent-1 mt-30 md:mt-30" href='samudragupt-resort'>
                    View
                </a>
              </div>
            </a>
          </div>

          <div class="col-lg-4 col-md-6" data-anim="slide-up delay-1">
            <a href="ashok-astoria" class="roomCard -type-2 -hover-button-center">
              <div class="roomCard__image -no-rounded ratio ratio-45:54 -hover-button-center__wrap">
                <img src="assets/img/joy/Ashok-Astoria-Townhous.jpg" alt="image" class="img-ratio"/>

              </div>

              <div class="roomCard__content mt-30">
                <div class="d-flex justify-between items-end">
                  <h3 class="roomCard__title lh-12 text-20 md:text-18">Ashok Astoria Town Home, Gangapur Road, Nashik </h3>
                </div>

                

                <a class="button -md -type-2 -outline-accent-1 mt-30 md:mt-30" href='ashok-astoria'>
                    View
                </a>
              </div>
            </a>
          </div>

          <div class="col-lg-4 col-md-6" data-anim="slide-up delay-1">
            <a href="malshej-resort" class="roomCard -type-2 -hover-button-center">
              <div class="roomCard__image -no-rounded ratio ratio-45:54 -hover-button-center__wrap">
                <img src="assets/img/joy/malshej/home-malshej.png" alt="image" class="img-ratio"/>

              </div>

              <div class="roomCard__content mt-30">
                <div class="d-flex justify-between items-end">
                  <h3 class="roomCard__title lh-12 text-20 md:text-18">Malshej Farmhouse, Mumbai-Nagar Road</h3>
                </div>

                

                <a class="button -md -type-2 -outline-accent-1 mt-30 md:mt-30" href='malshej-resort'>
                    View
                </a>
              </div>
            </a>
          </div>

        </div>
      </div>
    </section>
    </div>
  )
}
