import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import baguetteBox from "baguettebox.js";
import "baguettebox.js/dist/baguetteBox.min.css";

export const Home = () => {
  useEffect(() => {
    baguetteBox.run(".grid-gallery", {
      animation: "slideIn",
    });
  }, []);

  return (
    <main>
      <section data-anim-wrap class="hero -type-8">
        <div
          class="hero__slider js-section-slider mt-73-mobile-banner"
          data-gap="0"
          data-slider-cols="xl-1 lg-1 md-1 sm-1 base-1"
          data-nav-prev="js-sliderHero-prev"
          data-nav-next="js-sliderHero-next"
        >
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <a href="samudragupt-resort">
              <div class="hero__slide">
                <div class="hero__bg" data-anim-child="fade delay-2">
                  <img
                    src="assets/img/joy/banner-3.jpg"
                    alt="image"
                    class="img-ratio"
                  />
                </div>

                <div class="hero__content">
                  <div class="container">
                    <div data-anim-wrap class="row justify-center">
                      <div class="col-auto">
                        <div
                          data-split="lines"
                          data-anim-child="split-lines delay-5"
                          class="hero__content text-center"
                        >
                          {/* <!-- <div class="hero__subtitle text-white">
                          STAY WITH US FEEL LIKE HOME
                        </div> -->

                        <!-- <h1 class="hero__title text-white">
                          Stay Conveniency in The <br class="lg:d-none">
                          Heart of New York
                        </h1> --> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </a>
            </div>

            <div class="swiper-slide">
            <a href="ashok-astoria">
              <div class="hero__slide">
                <div class="hero__bg" data-anim-child="fade delay-2">
                  <img
                    src="assets/img/joy/ashok-astoria.jpg"
                    alt="image"
                    class="img-ratio"
                  />
                </div>

                <div class="hero__content">
                  <div class="container">
                    <div data-anim-wrap class="row justify-center">
                      <div class="col-auto">
                        <div
                          data-split="lines"
                          data-anim-child="split-lines delay-5"
                          class="hero__content text-center"
                        >
                          {/* <!-- <div class="hero__subtitle text-white">
                          STAY WITH US FEEL LIKE HOME
                        </div> -->

                        <!-- <h1 class="hero__title text-white">
                          Stay Conveniency in The <br class="lg:d-none">
                          Heart of New York
                        </h1> --> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </a>
            </div>

            <div class="swiper-slide">
            <a href="malshej-resort">
              <div class="hero__slide">
                <div class="hero__bg" data-anim-child="fade delay-2">
                  <img
                    src="assets/img/joy//malshej/malshej-resort-slider.png"
                    alt="image"
                    class="img-ratio"
                  />
                </div>

                <div class="hero__content">
                  <div class="container">
                    <div data-anim-wrap class="row justify-center">
                      <div class="col-auto">
                        <div
                          data-split="lines"
                          data-anim-child="split-lines delay-5"
                          class="hero__content text-center"
                        >
                          {/* <!-- <div class="hero__subtitle text-white">
                          STAY WITH US FEEL LIKE HOME
                        </div> --> */}

                          {/* <!-- <h1 class="hero__title text-white">
                          Stay Conveniency in The <br class="lg:d-none">
                          Heart of New York
                        </h1> --> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </a>
            </div>
          </div>
        </div>

      </section>

      <section class="layout-pt-lg layout-pb-lg">
        <div data-anim-wrap class="container">
          <div class="row y-gap-50 items-center justify-between">
            <div class="col-lg-5 col-md-9">
              <div
                data-anim-child="slide-up delay-1"
                class="text-15 uppercase mb-20 sm:mb-10 mobile-text-center"
              >
                About
              </div>
              <h2
                data-anim-child="slide-up delay-2"
                class="text-40 md:text-30 capitalize mobile-text-center"
              >
                Joy Trails Resort/Service Apartments
              </h2>
              <p
                data-anim-child="slide-up delay-3"
                class="lh-18 lg:pr-0 mt-40 md:mt-30 text-justify"
              >
                We Joy Trails India is a team of first generation entrepreneurs who are desirous  to provide you the best stay options at reasonable rates at different beautiful locations so that you enjoy your vacations with your loved ones. Everyone has a wish list of having a Dream Home near Beach, River, Lake, Valley, Mountain etc. However there are very few lucky persons to have all these of their own. <br/>
                Hence Joy Trails India has also come up with a unique idea of Club Membership for Vacations at all such locations, where everyone will love to stay, relax & enjoy.
                <br/>
                Join our Club Membership and you are welcome for family vacations at our beautiful stay options at affordable price.  
                <br/>
                Also you are welcome to book independent room for your family and enjoy the luxury stay at our stay options available.
              </p>
              <div
                data-anim-child="slide-up delay-4"
                class="d-flex mt-20 md:mt-10 hueyh"
              >
                <a
                  class="button -md -type-2 -outline-accent-1 mt-20 md:mt-10"
                  href="about-us"
                >
                  READ MORE
                </a>
              </div>
            </div>

            <div class="col-lg-6">
              <div data-anim-child="img-right cover-white delay-2">
                <div class="imageGrid">
                  <img
                    src="assets/img/joy/joy-tread-aboutus-2.png"
                    alt="image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="relative layout-pt-lg  layout-pb-lg">
        <div class="sectionBg -mx-60 bg-light-1 rounded-16"></div>
        <div data-anim-wrap class="container">
          <div
            data-anim-child="slide-up delay-2"
            class=" y-gap-30 text-center items-end"
          >
            <div class="col-auto">
              <div class="text-15 uppercase mb-20 sm:mb-10 yellow-heading" style={{fontWeight:"500"}}>EXPLORE</div>
              <h2 class="text-40 md:text-30 lh-065">Resorts & Suites</h2>
            </div>
          </div>

          <div class="relative">
            <div
              class="overflow-hidden mt-60 sm:mt-50 js-section-slider"
              data-gap="30"
              data-slider-cols="xl-3 lg-3 md-2 sm-1 base-1"
              data-nav-prev="js-slider2-prev"
              data-nav-next="js-slider2-next"
            >
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <a href="samudragupt-resort" class="roomCard -type-2 -hover-button-center">
                    <div data-anim-child="img-right cover-white delay-2">
                      <div class="roomCard__image ratio ratio-45:54 -hover-button-center__wrap">
                        <img
                          src="assets/img/joy/joy-tread-aboutus-2.png"
                          alt="image"
                          class="img-ratio rounded-16"
                        />

                        {/* <!-- <div class="roomCard__price text-15 fw-500 bg-white text-accent-1">₹ 499 / NIGHT</div> --> */}

                        {/* <!-- <div class="-hover-button-center__button flex-center size-130 rounded-full bg-accent-1-50 blur-1 border-white-10">
                        <span class="text-15 fw-500 text-white">BOOK NOW</span>
                      </div> --> */}
                      </div>
                    </div>

                    <div
                      data-anim-child="slide-up delay-3"
                      class="roomCard__content mt-30"
                    >
                      <div class="d-flex justify-between items-end">
                        <h3 class="roomCard__title lh-12 text-20 md:text-16">
                          Samudragupt Lifestyle Retreat , Gholvad Dahanu
                        </h3>
                      </div>

                      <div class="row x-gap-30 y-gap-10 pt-30"></div>
                    </div>
                  </a>
                </div>

                <div class="swiper-slide">
                  <a href="ashok-astoria" class="roomCard -type-2 -hover-button-center">
                    <div data-anim-child="img-right cover-white delay-4">
                      <div class="roomCard__image ratio ratio-45:54 -hover-button-center__wrap">
                        <img
                          src="assets/img/joy/Ashok-Astoria-Townhous.jpg"
                          alt="image"
                          class="img-ratio rounded-16"
                        />

                        {/* <!-- <div class="roomCard__price text-15 fw-500 bg-white text-accent-1">₹499 / NIGHT</div> --> */}

                        {/* <!-- <div class="-hover-button-center__button flex-center size-130 rounded-full bg-accent-1-50 blur-1 border-white-10">
                        <span class="text-15 fw-500 text-white">BOOK NOW</span>
                      </div> --> */}
                      </div>
                    </div>

                    <div
                      data-anim-child="slide-up delay-5"
                      class="roomCard__content mt-30"
                    >
                      <div class="d-flex justify-between items-end">
                        <h3 class="roomCard__title lh-12 text-20 md:text-16">
                          Ashok Astoria Town Home, Gangapur Road, Nashik{" "}
                        </h3>
                      </div>

                      <div class="row x-gap-30 y-gap-10 pt-30"></div>
                    </div>
                  </a>
                </div>

                <div class="swiper-slide">
                  <a href="malshej-resort" class="roomCard -type-2 -hover-button-center">
                    <div data-anim-child="img-right cover-white delay-4">
                      <div class="roomCard__image ratio ratio-45:54 -hover-button-center__wrap">
                        <img
                          src="assets/img/joy/malshej/home-malshej.png"
                          alt="image"
                          class="img-ratio rounded-16"
                        />

                        {/* <!-- <div class="roomCard__price text-15 fw-500 bg-white text-accent-1">₹499 / NIGHT</div> --> */}

                        {/* <!-- <div class="-hover-button-center__button flex-center size-130 rounded-full bg-accent-1-50 blur-1 border-white-10">
                        <span class="text-15 fw-500 text-white">BOOK NOW</span>
                      </div> --> */}
                      </div>
                    </div>

                    <div
                      data-anim-child="slide-up delay-5"
                      class="roomCard__content mt-30"
                    >
                      <div class="d-flex justify-between items-end">
                        <h3 class="roomCard__title lh-12 text-20 md:text-16 ">
                          Malshej Farmhouse, Mumbai-Nagar Road{" "}
                        </h3>
                      </div>

                      <div class="row x-gap-30 y-gap-10 pt-30"></div>
                    </div>
                  </a>
                </div>
              </div>
            </div>

            <div class="navAbsolute -type-2">
              <button class="button -outline-accent-1 size-60 md:size-60 flex-center rounded-full js-slider2-prev text-black">
                <i class="icon-arrow-left text-24 md:text-19"></i>
              </button>

              <button class="button -outline-accent-1 size-60 md:size-60 flex-center rounded-full js-slider2-next text-black">
                <i class="icon-arrow-right text-24 md:text-19"></i>
              </button>
            </div>
          </div>
        </div>
      </section>

      <section class="layout-pt-lg layout-pb-lg">
        <div class="container">
          <div data-anim-wrap class="row justify-center text-center">
            <div data-anim-child="slide-up delay-3" class="col-xl-7 col-lg-8">
              {/* <div class="text-15 uppercase mb-20 sm:mb-10">LUXURY RESORT</div> */}
              <h2 class="text-40 md:text-30">
                Our Beautiful Spaces
              </h2>
            </div>
          </div>

          <div data-anim-wrap class="row y-gap-30 items-center pt-60 sm:pt-50">
            <div class="col-lg-1"></div>
            <div
              data-anim-child="img-right cover-white delay-1"
              class="col-md-5"
            >
              <div class="ratio ratio-1:1">
                <img
                  src="assets/img/joy/image-1.png"
                  alt="image"
                  class="img-ratio rounded-16"
                />
              </div>
            </div>

            <div
              data-anim-child="slide-up delay-3"
              class="col-xl-5 col-lg-5 col-md-5 offset-md-1"
            >
              <i class="d-block icon-restaurant text-30 mb-20 text-center-mobile"></i>
              <h3 class="text-40 md:text-30 lh-065 text-center-mobile">Garden</h3>
              <p class="mt-30 text-justify">
              Welcome to our resort's enchanting garden, a tranquil oasis where nature's beauty flourishes in every corner. Stroll along the winding pathways adorned with vibrant flowers, lush greenery, and soothing water features that create a serene atmosphere.
              </p>
            </div>
          </div>

          <div data-anim-wrap class="row y-gap-30 items-center pt-80 sm:pt-50">
            {/* <div class="col-lg-1"></div> */}

            <div
              data-anim-child="slide-up delay-3"
              class="col-xl-5 col-lg-5 col-md-5 offset-md-1"
            >
              <i class="d-block icon-restaurant text-30 mb-20 text-center-mobile"></i>
              <h3 class="text-40 md:text-30 lh-065 text-center-mobile">Outside Sitting</h3>
              <p class="mt-30 text-justify">
              Experience the serene beauty of nature with our inviting outside sitting area, perfect for relaxation and unwinding. Surrounded by lush greenery and breathtaking views, our outdoor seating offers a peaceful escape from the hustle and bustle of daily life.
              </p>
            </div>
            <div class="col-lg-1"></div>
            <div
              data-anim-child="img-right cover-white delay-1"
              class="col-md-5"
            >
              <div class="ratio ratio-1:1">
                <img
                  src="assets/img/joy/image-2.png"
                  alt="image"
                  class="img-ratio rounded-16"
                />
              </div>
            </div>
          </div>

          <div data-anim-wrap class="row y-gap-30 items-center pt-80 sm:pt-50">
            <div class="col-lg-1"></div>
            <div
              data-anim-child="img-right cover-white delay-1"
              class="col-md-5"
            >
              <div class="ratio ratio-1:1">
                <img
                  src="assets/img/joy/image-3.png"
                  alt="image"
                  class="img-ratio rounded-16"
                />
              </div>
            </div>

            <div
              data-anim-child="slide-up delay-3"
              class="col-xl-5 col-lg-5 col-md-5 offset-md-1"
            >
              <i class="d-block icon-restaurant text-30 mb-20 text-center-mobile"></i>
              <h3 class="text-40 md:text-30 lh-065 text-center-mobile">Temple</h3>
              <p class="mt-30 text-justify">
              Experience serenity at the nearby ancient temple, a tranquil retreat that blends spiritual ambiance with breathtaking architecture. Nestled amidst lush greenery, the temple is a peaceful sanctuary where tradition meets tranquility.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section data-anim-wrap class="relative layout-pt-lg layout-pb-lg d-none">
        <div
          data-anim-child="slide-up delay-2"
          class="sectionBg -mx-60 bg-light-1 rounded-16"
        ></div>

        <div class="container">
          <div class="row justify-center text-center">
            <div data-anim-child="slide-up delay-4" class="col-auto">
              <div class="text-15 uppercase mb-20 sm:mb-10">OUR SERVICES</div>
              <h2 class="text-40 md:text-30">Resort Facilities</h2>
            </div>
          </div>

          <div class="row y-gap-30 justify-between pt-60 sm:pt-50">
            <div
              data-anim-child="slide-up delay-3"
              class="col-lg-auto col-md-4 col-6"
            >
              <div class="iconCard -type-1 -hover-1">
                <div class="iconCard__icon text-50">
                  <div class="iconCard__icon__circle"></div>
                  <i class="icon-wifi"></i>
                </div>
                <h4 class="text-24 text-center mt-20">Wifi &amp; Internet</h4>
              </div>
            </div>

            <div
              data-anim-child="slide-up delay-4"
              class="col-lg-auto col-md-4 col-6"
            >
              <div class="iconCard -type-1 -hover-1">
                <div class="iconCard__icon text-50">
                  <div class="iconCard__icon__circle"></div>
                  <i class="icon-bus"></i>
                </div>
                <h4 class="text-24 text-center mt-20">Parking</h4>
              </div>
            </div>

            <div
              data-anim-child="slide-up delay-5"
              class="col-lg-auto col-md-4 col-6"
            >
              <div class="iconCard -type-1 -hover-1">
                <div class="iconCard__icon text-50">
                  <div class="iconCard__icon__circle"></div>
                  <i class="icon-tv"></i>
                </div>
                <h4 class="text-24 text-center mt-20">TV</h4>
              </div>
            </div>

            <div
              data-anim-child="slide-up delay-6"
              class="col-lg-auto col-md-4 col-6"
            >
              <div class="iconCard -type-1 -hover-1">
                <div class="iconCard__icon text-50">
                  <div class="iconCard__icon__circle"></div>
                  <i class="icon-bed"></i>
                </div>
                <h4 class="text-24 text-center mt-20">Air Conditioning</h4>
              </div>
            </div>

            <div
              data-anim-child="slide-up delay-7"
              class="col-lg-auto col-md-4 col-6"
            >
              <div class="iconCard -type-1 -hover-1">
                <div class="iconCard__icon text-50">
                  <div class="iconCard__icon__circle"></div>
                  <i class="icon-laundry"></i>
                </div>
                <h4 class="text-24 text-center mt-20">Washing machine</h4>
              </div>
            </div>

            <div
              data-anim-child="slide-up delay-8"
              class="col-lg-auto col-md-4 col-6"
            >
              <div class="iconCard -type-1 -hover-1">
                <div class="iconCard__icon text-50">
                  <div class="iconCard__icon__circle"></div>
                  <i class="icon-housekeeper"></i>
                </div>
                <h4 class="text-24 text-center mt-20">Housekeeper Services</h4>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="relative layout-pt-lg layout-pb-lg grid-gallery">
        <div class="sectionBg -mx-60 bg-light-1 rounded-16"></div>
        <div data-anim-wrap="true" class="container animated">
          <div class="row y-gap-40 items-center">
            <div data-anim-child="slide-up delay-2" class="col-xl-3 is-in-view">
              <div class="text-15 uppercase mb-20 sm:mb-10 mobile-text-center yellow-heading" style={{fontWeight:"500"}}>
                There's So Much to Discover
              </div>
              <h2 class="text-40 md:text-30 mobile-text-center">
                Unique Experiences
              </h2>
              {/* <p class="text-black lh-18 mt-40 md:mt-20 mobile-text-center text-white">
                Welcome to the best five-star deluxe <br class="lg:d-none" />
                Resorts in India.
              </p> */}
              <div class="d-flex items-center mt-60 md:mt-30">
                <button
                  class="button -outline-white size-50 flex-center rounded-full js-slider3-prev swiper-button-disabled"
                  disabled=""
                  tabindex="-1"
                  aria-label="Previous slide"
                  aria-controls="swiper-wrapper-faa753c3ebeea5109"
                  aria-disabled="true"
                >
                  <i class="icon-arrow-left text-15 text-black"></i>
                </button>
                <button
                  class="button -outline-white size-50 flex-center rounded-full ml-30 js-slider3-next"
                  tabindex="0"
                  aria-label="Next slide"
                  aria-controls="swiper-wrapper-faa753c3ebeea5109"
                  aria-disabled="false"
                >
                  <i class="icon-arrow-right text-15 text-black"></i>
                </button>
              </div>
            </div>
            <div class="col-xl-9">
              <div class="pl-40 lg:pl-0">
                <div
                  class="relative overflow-hidden js-section-slider swiper-initialized swiper-horizontal swiper-pointer-events swiper-autoheight swiper-watch-progress swiper-backface-hidden"
                  data-gap="20"
                  data-slider-cols="xl-3 lg-3 md-2 sm-1 base-1"
                  data-nav-prev="js-slider3-prev"
                  data-nav-next="js-slider3-next"
                >
                  <div
                    class="swiper-wrapper"
                    id="swiper-wrapper-faa753c3ebeea5109"
                    aria-live="polite"
                  >
                    <div
                      class="swiper-slide swiper-slide-visible swiper-slide-active"
                      role="group"
                      aria-label="1 / 6"
                    >
                      <div
                        data-anim-child="img-right cover-accent-1 delay-2"
                        class="is-in-view"
                      >
                        <a href="#" class="baseCard -type-3">
                          <div class="baseCard__image ratio ratio-33:45">
                            <a href="assets/img/joy/joy-tread-aboutus.png">
                              <img
                                src="assets/img/joy/joy-tread-aboutus.png"
                                alt="image"
                                class="img-ratio rounded-16"
                              />
                            </a>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div
                      class="swiper-slide swiper-slide-visible swiper-slide-next"
                      role="group"
                      aria-label="2 / 6"
                    >
                      <div
                        data-anim-child="img-right cover-accent-1 delay-4"
                        class="is-in-view"
                      >
                        <a href="#" class="baseCard -type-3">
                          <div class="baseCard__image ratio ratio-33:45">
                            <a href="assets/img/joy/joy-retread-owner.jpeg">
                              <img
                                src="assets/img/joy/joy-retread-owner.jpeg"
                                alt="image"
                                class="img-ratio rounded-16"
                              />
                            </a>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div
                      class="swiper-slide swiper-slide-visible"
                      role="group"
                      aria-label="3 / 6"
                    >
                      <div
                        data-anim-child="img-right cover-accent-1 delay-6"
                        class="is-in-view"
                      >
                        <a href="#" class="baseCard -type-3">
                          <div class="baseCard__image ratio ratio-33:45">
                            <a href="assets/img/joy/joy-retread-lotus.jpeg">
                              <img
                                src="assets/img/joy/joy-retread-lotus.jpeg"
                                alt="image"
                                class="img-ratio rounded-16"
                              />
                            </a>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class="swiper-slide" role="group" aria-label="4 / 6">
                      <div
                        data-anim-child="img-right cover-accent-1 delay-8"
                        class="is-in-view"
                      >
                        <a href="#" class="baseCard -type-3">
                          <div class="baseCard__image ratio ratio-33:45">
                            <a href="assets/img/joy/joy-tread-aboutus.png">
                              <img
                                src="assets/img/joy/joy-tread-aboutus.png"
                                alt="image"
                                class="img-ratio rounded-16"
                              />
                            </a>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class="swiper-slide" role="group" aria-label="5 / 6">
                      <div
                        data-anim-child="img-right cover-accent-1 delay-10"
                        class="is-in-view"
                      >
                        <a href="#" class="baseCard -type-3">
                          <div class="baseCard__image ratio ratio-33:45">
                            <a href="assets/img/joy/joy-retread-owner.jpeg">
                              <img
                                src="assets/img/joy/joy-retread-owner.jpeg"
                                alt="image"
                                class="img-ratio rounded-16"
                              />
                            </a>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class="swiper-slide" role="group" aria-label="6 / 6">
                      <div
                        data-anim-child="img-right cover-accent-1 delay-12"
                        class="is-in-view"
                      >
                        <a href="#" class="baseCard -type-3">
                          <div class="baseCard__image ratio ratio-33:45">
                            <a href="assets/img/joy/joy-retread-lotus.jpeg">
                              <img
                                src="assets/img/joy/joy-retread-lotus.jpeg"
                                alt="image"
                                class="img-ratio rounded-16"
                              />
                            </a>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <span
                    class="swiper-notification"
                    aria-live="assertive"
                    aria-atomic="true"
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section data-anim-wrap class="layout-pt-lg layout-pb-lg">
        <div class="container">
          <div class="row y-gap-30 justify-center text-center">
            <div
              data-split="lines"
              data-anim-child="split-lines delay-2"
              class="col-auto"
            >
              <div class="text-15 uppercase mb-20 sm:mb-10">
                VOICE FROM OUR GUESTS
              </div>
              <h2 class="text-40 md:text-30 lh-065">Testimonials</h2>
            </div>
          </div>

          <div class="row y-gap-30 pt-50 sm:pt-50">
            <div data-anim-child="slide-up delay-3" class="col-lg-4 col-md-6">
              <div class="py-40 px-60 sm:py-30 sm:px-30 jhduhdj">
                <div class="mb-30">
                  <svg
                    width="31"
                    height="31"
                    viewBox="0 0 31 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g filter="url(#filter0_d_532_344)">
                      <path
                        d="M6.20438 24.5897C4.25791 24.5897 2.73723 23.9181 1.64234 22.575C0.547445 21.1709 0 19.0952 0 16.3479C0 13.4786 0.638686 10.7313 1.91606 8.10617C3.25426 5.48102 5.1399 2.88639 7.57299 0.322292C7.63382 0.261239 7.72506 0.230713 7.84672 0.230713C8.0292 0.230713 8.15085 0.322289 8.21168 0.50544C8.33333 0.627539 8.36375 0.780164 8.30292 0.963315C6.84307 2.91692 5.83942 4.80947 5.29197 6.64097C4.80535 8.41142 4.56204 10.3956 4.56204 12.5934C4.56204 14.2417 4.77494 15.5238 5.20073 16.4395C5.62652 17.3553 6.20438 18.1794 6.93431 18.912L3.46715 19.5531C3.40633 18.5152 3.68005 17.7216 4.28832 17.1721C4.95742 16.6227 5.809 16.3479 6.84307 16.3479C8.12044 16.3479 9.09367 16.7142 9.76277 17.4468C10.4927 18.1794 10.8577 19.1868 10.8577 20.4688C10.8577 21.8119 10.4319 22.8498 9.58029 23.5824C8.78954 24.2539 7.66423 24.5897 6.20438 24.5897ZM20.2555 24.5897C18.309 24.5897 16.7883 23.9181 15.6934 22.575C14.6594 21.1709 14.1423 19.0952 14.1423 16.3479C14.1423 13.4175 14.781 10.6397 16.0584 8.0146C17.3358 5.38944 19.2214 2.82534 21.7153 0.322292C21.7762 0.261239 21.8674 0.230713 21.9891 0.230713C22.1715 0.230713 22.2932 0.322289 22.354 0.50544C22.4757 0.627539 22.5061 0.780164 22.4453 0.963315C20.9854 2.91692 19.9818 4.80947 19.4343 6.64097C18.9477 8.41142 18.7044 10.3956 18.7044 12.5934C18.7044 14.2417 18.8869 15.5543 19.2518 16.5311C19.6776 17.4468 20.2555 18.2405 20.9854 18.912L17.6095 19.5531C17.5487 18.5152 17.8224 17.7216 18.4307 17.1721C19.0389 16.6227 19.8905 16.3479 20.9854 16.3479C22.2628 16.3479 23.236 16.7142 23.9051 17.4468C24.635 18.1794 25 19.1868 25 20.4688C25 21.8119 24.5742 22.8498 23.7226 23.5824C22.9319 24.2539 21.7762 24.5897 20.2555 24.5897Z"
                        fill="#122223"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_532_344"
                        x="0"
                        y="0.230713"
                        width="31"
                        height="30.359"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood
                          flood-opacity="0"
                          result="BackgroundImageFix"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dx="6" dy="6" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_532_344"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_532_344"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                </div>

                <div class="text-17 lh-18">
                  “Some places are so good to stay in, you don’t want to
                  generate a rush on them. The Resort lodge hotel in London is
                  one of them.”
                </div>

                <div class="line -horizontal bg-border mt-30 mb-30"></div>

                <div class="d-flex items-center">
                  <img
                    src="assets/img/avatars/1/1.png"
                    alt="image"
                    class="size-70"
                  />

                  <div class="ml-20">
                    <div class="">Ali Tufan</div>
                    <div class="text-15">Product Manager, Apple Inc</div>
                  </div>
                </div>
              </div>
            </div>

            <div data-anim-child="slide-up delay-4" class="col-lg-4 col-md-6">
              <div class="py-40 px-60 sm:py-30 sm:px-30 jhduhdj">
                <div class="mb-30">
                  <svg
                    width="31"
                    height="31"
                    viewBox="0 0 31 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g filter="url(#filter0_d_532_344)">
                      <path
                        d="M6.20438 24.5897C4.25791 24.5897 2.73723 23.9181 1.64234 22.575C0.547445 21.1709 0 19.0952 0 16.3479C0 13.4786 0.638686 10.7313 1.91606 8.10617C3.25426 5.48102 5.1399 2.88639 7.57299 0.322292C7.63382 0.261239 7.72506 0.230713 7.84672 0.230713C8.0292 0.230713 8.15085 0.322289 8.21168 0.50544C8.33333 0.627539 8.36375 0.780164 8.30292 0.963315C6.84307 2.91692 5.83942 4.80947 5.29197 6.64097C4.80535 8.41142 4.56204 10.3956 4.56204 12.5934C4.56204 14.2417 4.77494 15.5238 5.20073 16.4395C5.62652 17.3553 6.20438 18.1794 6.93431 18.912L3.46715 19.5531C3.40633 18.5152 3.68005 17.7216 4.28832 17.1721C4.95742 16.6227 5.809 16.3479 6.84307 16.3479C8.12044 16.3479 9.09367 16.7142 9.76277 17.4468C10.4927 18.1794 10.8577 19.1868 10.8577 20.4688C10.8577 21.8119 10.4319 22.8498 9.58029 23.5824C8.78954 24.2539 7.66423 24.5897 6.20438 24.5897ZM20.2555 24.5897C18.309 24.5897 16.7883 23.9181 15.6934 22.575C14.6594 21.1709 14.1423 19.0952 14.1423 16.3479C14.1423 13.4175 14.781 10.6397 16.0584 8.0146C17.3358 5.38944 19.2214 2.82534 21.7153 0.322292C21.7762 0.261239 21.8674 0.230713 21.9891 0.230713C22.1715 0.230713 22.2932 0.322289 22.354 0.50544C22.4757 0.627539 22.5061 0.780164 22.4453 0.963315C20.9854 2.91692 19.9818 4.80947 19.4343 6.64097C18.9477 8.41142 18.7044 10.3956 18.7044 12.5934C18.7044 14.2417 18.8869 15.5543 19.2518 16.5311C19.6776 17.4468 20.2555 18.2405 20.9854 18.912L17.6095 19.5531C17.5487 18.5152 17.8224 17.7216 18.4307 17.1721C19.0389 16.6227 19.8905 16.3479 20.9854 16.3479C22.2628 16.3479 23.236 16.7142 23.9051 17.4468C24.635 18.1794 25 19.1868 25 20.4688C25 21.8119 24.5742 22.8498 23.7226 23.5824C22.9319 24.2539 21.7762 24.5897 20.2555 24.5897Z"
                        fill="#122223"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_532_344"
                        x="0"
                        y="0.230713"
                        width="31"
                        height="30.359"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood
                          flood-opacity="0"
                          result="BackgroundImageFix"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dx="6" dy="6" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_532_344"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_532_344"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                </div>

                <div class="text-17 lh-18">
                  “Some places are so good to stay in, you don’t want to
                  generate a rush on them. The Resort lodge hotel in London is
                  one of them.”
                </div>

                <div class="line -horizontal bg-border mt-30 mb-30"></div>

                <div class="d-flex items-center">
                  <img
                    src="assets/img/avatars/1/1.png"
                    alt="image"
                    class="size-70"
                  />

                  <div class="ml-20">
                    <div class="">Ali Tufan</div>
                    <div class="text-15">Product Manager, Apple Inc</div>
                  </div>
                </div>
              </div>
            </div>

            <div data-anim-child="slide-up delay-5" class="col-lg-4 col-md-6">
              <div class="py-40 px-60 sm:py-30 sm:px-30 jhduhdj">
                <div class="mb-30">
                  <svg
                    width="31"
                    height="31"
                    viewBox="0 0 31 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g filter="url(#filter0_d_532_344)">
                      <path
                        d="M6.20438 24.5897C4.25791 24.5897 2.73723 23.9181 1.64234 22.575C0.547445 21.1709 0 19.0952 0 16.3479C0 13.4786 0.638686 10.7313 1.91606 8.10617C3.25426 5.48102 5.1399 2.88639 7.57299 0.322292C7.63382 0.261239 7.72506 0.230713 7.84672 0.230713C8.0292 0.230713 8.15085 0.322289 8.21168 0.50544C8.33333 0.627539 8.36375 0.780164 8.30292 0.963315C6.84307 2.91692 5.83942 4.80947 5.29197 6.64097C4.80535 8.41142 4.56204 10.3956 4.56204 12.5934C4.56204 14.2417 4.77494 15.5238 5.20073 16.4395C5.62652 17.3553 6.20438 18.1794 6.93431 18.912L3.46715 19.5531C3.40633 18.5152 3.68005 17.7216 4.28832 17.1721C4.95742 16.6227 5.809 16.3479 6.84307 16.3479C8.12044 16.3479 9.09367 16.7142 9.76277 17.4468C10.4927 18.1794 10.8577 19.1868 10.8577 20.4688C10.8577 21.8119 10.4319 22.8498 9.58029 23.5824C8.78954 24.2539 7.66423 24.5897 6.20438 24.5897ZM20.2555 24.5897C18.309 24.5897 16.7883 23.9181 15.6934 22.575C14.6594 21.1709 14.1423 19.0952 14.1423 16.3479C14.1423 13.4175 14.781 10.6397 16.0584 8.0146C17.3358 5.38944 19.2214 2.82534 21.7153 0.322292C21.7762 0.261239 21.8674 0.230713 21.9891 0.230713C22.1715 0.230713 22.2932 0.322289 22.354 0.50544C22.4757 0.627539 22.5061 0.780164 22.4453 0.963315C20.9854 2.91692 19.9818 4.80947 19.4343 6.64097C18.9477 8.41142 18.7044 10.3956 18.7044 12.5934C18.7044 14.2417 18.8869 15.5543 19.2518 16.5311C19.6776 17.4468 20.2555 18.2405 20.9854 18.912L17.6095 19.5531C17.5487 18.5152 17.8224 17.7216 18.4307 17.1721C19.0389 16.6227 19.8905 16.3479 20.9854 16.3479C22.2628 16.3479 23.236 16.7142 23.9051 17.4468C24.635 18.1794 25 19.1868 25 20.4688C25 21.8119 24.5742 22.8498 23.7226 23.5824C22.9319 24.2539 21.7762 24.5897 20.2555 24.5897Z"
                        fill="#122223"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_532_344"
                        x="0"
                        y="0.230713"
                        width="31"
                        height="30.359"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood
                          flood-opacity="0"
                          result="BackgroundImageFix"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dx="6" dy="6" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_532_344"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_532_344"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                </div>

                <div class="text-17 lh-18">
                  “Some places are so good to stay in, you don’t want to
                  generate a rush on them. The Resort lodge hotel in London is
                  one of them.”
                </div>

                <div class="line -horizontal bg-border mt-30 mb-30"></div>

                <div class="d-flex items-center">
                  <img
                    src="assets/img/avatars/1/1.png"
                    alt="image"
                    class="size-70"
                  />

                  <div class="ml-20">
                    <div class="">Ali Tufan</div>
                    <div class="text-15 ">Product Manager, Apple Inc</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="layout-pb-lg">
        <div data-anim-wrap class="px-60 md:px-20">
          <div class="row justify-center text-center">
            <div
              data-split="lines"
              data-anim-child="split-lines delay-1"
              class="col-auto"
            >
              <div class="text-15 uppercase mb-20 sm:mb-10">Video Gallery</div>
              <h2 class="text-40 md:text-30">Video Gallery</h2>
            </div>
          </div>

          <div class="row y-gap-30 pt-60 sm:pt-50">
            <div class="w-1/3 md:w-1/2 sm:w-1/1">
              <div data-anim-child="img-right cover-white delay-2">
                {/* <!--<a href="#" class="ratio ratio-1:1">-->
              <!--  <img src="img/joy/joy-1.jpg" alt="image" class="img-ratio rounded-16">-->
              <!--</a>--> */}
                <iframe
                  width="100%"
                  height="300px"
                  src="https://img.stayvista.com/meals/food-and-beverage-meals.mp4"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
            <div class="w-1/3 md:w-1/2 sm:w-1/1">
              <div data-anim-child="img-right cover-white delay-2">
                {/* <!--<a href="#" class="ratio ratio-1:1">-->
              <!--  <img src="img/joy/joy-1.jpg" alt="image" class="img-ratio rounded-16">-->
              <!--</a>--> */}
                <iframe
                  width="100%"
                  height="300px"
                  src="https://img.stayvista.com/meals/food-and-beverage-meals.mp4"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
            </div>

            {/* <!--<div class="w-1/4 md:w-1/2 sm:w-1/1">-->
          <!--  <div data-anim-child="img-right cover-white delay-4">-->
          <!--    <a href="#" class="ratio ratio-1:1">-->
          <!--      <img src="img/joy/joy-2.jpg" alt="image" class="img-ratio rounded-16">-->
          <!--    </a>-->
          <!--  </div>-->
          <!--</div>-->

          <!--<div class="w-1/4 md:w-1/2 sm:w-1/1">-->
          <!--  <div data-anim-child="img-right cover-white delay-6">-->
          <!--    <a href="#" class="ratio ratio-1:1">-->
          <!--      <img src="img/joy/joy-3.webp" alt="image" class="img-ratio rounded-16">-->
          <!--    </a>-->
          <!--  </div>-->
          <!--</div>-->

          <!--<div class="w-1/4 md:w-1/2 sm:w-1/1">-->
          <!--  <div data-anim-child="img-right cover-white delay-8">-->
          <!--    <a href="#" class="ratio ratio-1:1">-->
          <!--      <img src="img/joy/joy-4.jpg" alt="image" class="img-ratio rounded-16">-->
          <!--    </a>-->
          <!--  </div>-->
          <!--</div>--> */}

            {/* <!-- <div class="w-1/5 md:w-1/2 sm:w-1/1">
            <div data-anim-child="img-right cover-white delay-10">
              <a href="#" class="ratio ratio-1:1">
                <img src="img/joy/joy-5.jpg" alt="image" class="img-ratio rounded-16">
              </a>
            </div>
          </div> --> */}
          </div>
        </div>
      </section>

      <section class="layout-pt-lg layout-pb-lg d-none">
        <div data-anim-wrap class="container">
          <div class="y-gap-30 items-end text-center">
            <div
              data-split="lines"
              data-anim-child="split-lines delay-2"
              class="col-auto"
            >
              <div class="text-15 uppercase mb-20 sm:mb-10">BLOG</div>
              <h2 class="text-40 md:text-30">Offers & News</h2>
            </div>
          </div>

          <div class="row y-gap-30 x-gap-85 justify-between pt-50 sm:pt-50">
            <div class="col-lg-4 col-md-6">
              <a href="#" class="baseCard -type-2">
                <div
                  class="baseCard__image ratio ratio-41:50"
                  data-anim-child="img-right cover-white delay-2"
                >
                  <img
                    src="assets/img/cards/2/1.png"
                    alt="image"
                    class="img-ratio"
                  />
                </div>

                <div
                  class="baseCard__content mt-30"
                  data-anim-child="slide-up delay-6"
                >
                  <div class="row x-gap-10 text-accent-1">
                    <div class="col-auto">06 April 2023</div>
                  </div>

                  <h4 class="text-18 md:text-25 mt-15">
                    Hotel June&#39;s Upcoming Artist in Residence
                  </h4>

                  <div class="d-flex mt-20">
                    <button class="button -md -type-2 -outline-accent-1 mt-20 md:mt-30">
                      READ MORE
                    </button>
                  </div>
                </div>
              </a>
            </div>

            <div class="col-lg-4 col-md-6">
              <a href="#" class="baseCard -type-2">
                <div
                  class="baseCard__image ratio ratio-41:50"
                  data-anim-child="img-right cover-white delay-4"
                >
                  <img
                    src="assets/img/cards/2/2.png"
                    alt="image"
                    class="img-ratio"
                  />
                </div>

                <div
                  class="baseCard__content mt-30"
                  data-anim-child="slide-up delay-7"
                >
                  <div class="row x-gap-10 text-accent-1">
                    <div class="col-auto">06 April 2023</div>
                  </div>

                  <h4 class="text-18 md:text-25 mt-15">
                    Where to travel in 2023: 10 places you need to go in 2023!
                  </h4>

                  <div class="d-flex mt-20">
                    <button class="button -md -type-2 -outline-accent-1 mt-20 md:mt-30">
                      READ MORE
                    </button>
                  </div>
                </div>
              </a>
            </div>

            <div class="col-lg-4 col-md-6">
              <a href="#" class="baseCard -type-2">
                <div
                  class="baseCard__image ratio ratio-41:50"
                  data-anim-child="img-right cover-white delay-6"
                >
                  <img
                    src="assets/img/cards/2/3.png"
                    alt="image"
                    class="img-ratio"
                  />
                </div>

                <div
                  class="baseCard__content mt-30"
                  data-anim-child="slide-up delay-8"
                >
                  <div class="row x-gap-10 text-accent-1">
                    <div class="col-auto">06 April 2023</div>
                  </div>

                  <h4 class="text-18 md:text-25 mt-15">
                    Tips For Picking Vacation Accommodation
                  </h4>

                  <div class="d-flex mt-20">
                    <button class="button -md -type-2 -outline-accent-1 mt-20 md:mt-30">
                      READ MORE
                    </button>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};
