import React from 'react'
import { createBrowserRouter, RouterProvider, Routes, Route } from "react-router-dom";
import { Home } from '../Pages/Home';
import { AboutUs } from '../Pages/AboutUs';
import { ContactUs } from '../Pages/ContactUs';
import { Gallery } from '../Pages/Gallery';
import { Services } from '../Pages/Services';
import { Desination } from '../Pages/Desination';
import { EnquiryForm } from '../Pages/EnquiryForm';
import { Samudragupt } from '../Pages/Samudragupt';
import { AshokAstoria } from '../Pages/AshokAstoria';
import { MalshejResort } from '../Pages/MalshejResort';


// const router = createBrowserRouter([
//     {
//       path: "/",
//       element: <Home/>,
//     },
//     {
//       path: "about-us",
//       element: <AboutUs/>,
//     },
//     {
//       path: "services",
//       element: <Services/>,
//     },
//     {
//       path: "gallery",
//       element: <Gallery/>,
//     },
//     {
//       path: "destination",
//       element: <Desination/>,
//     },
//     {
//       path: "contact-us",
//       element: <ContactUs/>,
//     },
//   ]);

export const AllRoutes = () => {
  return (
    <div>
  
        <Routes>
          <Route index element={<Home></Home>} />
          <Route path="about-us" element={<AboutUs/>} />
          <Route path="services" element={<Services/>} />
          <Route path="gallery" element={<Gallery/>} />
          <Route path="destination" element={<Desination/>} />
          <Route path="contact-us" element={<ContactUs/>} />
          <Route path="enquiry-form" element={<EnquiryForm/>} />
          <Route path="samudragupt-resort" element={<Samudragupt/>} />
          <Route path="ashok-astoria" element={<AshokAstoria/>} />
          <Route path="malshej-resort" element={<MalshejResort/>} />
        </Routes>

    </div>
  )
}
