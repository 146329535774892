import React from 'react'
import { Link } from 'react-router-dom'

export const Header = () => {
  return (
    <main>
    <div class="menuFullScreen js-menuFullScreen">
    <div class="menuFullScreen__topMobile js-menuFullScreen-topMobile">
      <div class="d-flex items-center text-white js-menuFullScreen-toggle">
        <i class="icon-menu text-9"></i>
        <div class="text-15 uppercase ml-30 sm:d-none">Menu</div>
      </div>

      <div class="">
        <img src="assets/img/joy/joy-trail-logo.png" alt="logo"/>
      </div>

    </div>

    <div class="menuFullScreen__mobile__bg js-menuFullScreen-mobile-bg"></div>

    <div class="menuFullScreen__left">
      <div class="menuFullScreen__bg js-menuFullScreen-bg">
        <img src="assets/img/menu/bg.png" alt="image"/>
      </div>

      <button class="menuFullScreen__close js-menuFullScreen-toggle js-menuFullScreen-close-btn">
        <span class="icon">
          <span></span>
          <span></span>
        </span>
        CLOSE
      </button>

      <div class="menuFullScreen-links js-menuFullScreen-links">

    

        <div class="menuFullScreen-links__item">
          <a href="/">
            Home
          </a>
        </div>
        <div class="menuFullScreen-links__item">
          <a href="about-us">
            About Us
          </a>
        </div>
        
        <div class="menuFullScreen-links__item js-menuFullScreen-has-children">
          <a href="#">
          Destination
            <i class="icon-arrow-right"></i>
            <i class="icon-chevron-right"></i>
          </a>

          <div class="menuFullScreen-links-subnav js-menuFullScreen-subnav">

            <div class="menuFullScreen-links-subnav__item">
              <a href="samudragupt-resort">Samudragupt Lifestyle Retreat</a>
            </div>

            <div class="menuFullScreen-links-subnav__item">
              <a href="ashok-astoria">Town Home in Ashok astoria</a>
            </div>

            <div class="menuFullScreen-links-subnav__item">
              <a href="malshej-resort">Malshej Farmhouse</a>
            </div>
          </div>
        </div>

        <div class="menuFullScreen-links__item">
          <a href="gallery">
          Gallery
          </a>
        </div>
        <div class="menuFullScreen-links__item">
          <a href="contact-us">
            Contact Us
          </a>
        </div>

      </div>
    </div>

    {/* <div class="menuFullScreen__right js-menuFullScreen-right">
      <div class="text-center">
        <div class="mb-100">
          <img src="assets/img/general/logo-black.svg" alt="image"/>
        </div>

        <div class="text-sec lh-11 fw-500 text-40">
          Hotel & Spa Swiss<br/>
          Resort
        </div>

        <div class="mt-40">
          <div class="text-30 text-sec fw-500">
            Location
          </div>
          <div class="mt-10">
            PO Box 16122 Collins Street West<br/>
            Victoria 8007 Australia
          </div>
        </div>

        <div class="mt-40">
          <div class="text-30 text-sec fw-500">
            Phone Support
          </div>
          <div class="mt-10">
            <div>
              <a href="#">+ 01 345 647 745</a>
            </div>
            <div>
              <a href="#">info@joyresort.com</a>
            </div>
          </div>
        </div>

        <div class="mt-40">
          <div class="text-30 text-sec fw-500">
            Connect With Us
          </div>
          <div class="mt-10">
            <a href="#">+ 01 345 647 745</a>
          </div>
        </div>
      </div>
    </div> */}

    {/* <!--<div class="menuFullScreen__bottomMobile js-menuFullScreen-buttomMobile">-->
    <!--  <button class="button rounded-200 w-1/1 py-20 -light-1 bg-accent-2">-->
    <!--    BOOK YOUR STAY-->
    <!--  </button>-->

    <!--  <a href="#" class="d-flex items-center mt-40">-->
    <!--    <i class="icon-phone mr-10"></i>-->
    <!--    <span>+41-96567-7854</span>-->
    <!--  </a>-->

    <!--  <a href="#" class="d-flex mt-20">-->
    <!--    <i class="icon-map mr-10"></i>-->
    <!--    <span>-->
    <!--      PO Box 16122 Collins Street West,-->
    <!--      Australia-->
    <!--    </span>-->
    <!--  </a>-->

    <!--  <a href="#" class="d-flex items-center mt-20">-->
    <!--    <i class="icon-mail mr-10"></i>-->
    <!--    <span>info@joy-resort.com</span>-->
    <!--  </a>-->
    <!--</div>--> */}
  </div>

  <div class="d-flex items-center bg-light-1 h-40 md:d-none">
      <div class="container">
        <div class="row y-gap-20 justify-between">
         
          <div class="col-auto">
            <div class="d-flex">
              <a href="#" class="d-flex items-center">
                <i class="icon-phone mr-10"></i>
                <span class="text-13">+91 9137086572</span>
              </a>

              <a href="joytrailsindia@gmail.com" class="d-flex items-center ml-30">
                <i class="icon-email mr-10"></i>
                <span class="text-13">joytrailsindia@gmail.com</span>
              </a>
            </div>
          </div>
          
          <div class="col-auto">
              <div class="d-flex items-center">
                <a href="#" class="mr-10 ml-10">
                    <i class="icon-facebook fab"></i>
                </a>
                <a href="#" class="mr-10 ml-10">
                    <i class="icon-twitter fab"></i>
                </a>
                <a href="#" class="mr-10 ml-10">
                    <i class="icon-instagram fab"></i>
                </a>
                <a href="#" class="mr-10 ml-10">
                    <i class="icon-linkedin fab"></i>
                </a>
              </div>
          </div>
          
        </div>
      </div>
    </div>

    <header class="header -h-90 mt-40 md:mt-0 js-header header-color" data-add-bg="" data-x="header" data-x-toggle="-is-menu-opened">
      <div class="header__container container">
          
        <div class="header__left">
          <div class="header__logo">
          <a href="/">
            <img src="assets/img/joy/joy-trail-logo.png" alt="logo"/>
          </a>
          </div>
        </div> 
          
        <div class="headerMobile__left">
          <div class="d-none lg:d-flex items-center js-menuFullScreen-toggle">
            <i class="icon-menu text-9 "></i>
            <div class="text-15 uppercase ml-30 sm:d-none">Menu</div>
          </div>

          <div class="lg:d-none">
            <div class="desktopNav">
              <div class="desktopNav__item">
                <a href="/" className=''>
                  Home
                </a>
              </div>
              <div class="desktopNav__item">
                <a href="about-us" className=''>
                  About Us
                </a>
              </div>
              <div class="desktopNav__item">
                <a href="destination" className=''>
                Destination <i class="icon-chevron-down"></i>
                </a>

                <div class="desktopNavSubnav">
                  <div class="desktopNavSubnav__content">

                    <div class="desktopNavSubnav__item">
                      <a href="samudragupt-resort">Samudragupt Lifestyle Retreat</a>
                    </div>

                    <div class="desktopNavSubnav__item">
                      <a href="ashok-astoria">Town Home in Ashok astoria</a>
                    </div>

                    <div class="desktopNavSubnav__item">
                      <a href="malshej-resort">Malshej Farmhouse</a>
                    </div>

                  </div>
                </div>
              </div>
              
              <div class="desktopNav__item">
              <a href="gallery" className=''>
                Gallery
              </a>
            </div>
            <div class="desktopNav__item">
              <a href="contact-us" className=''>
                Contact Us
              </a>
            </div>

            <button class="button -md -accent-1 bg-accent-2 ml-40 xl:d-none">
              <i class="icon-phone mr-15"></i>
              +91 9137086572
            </button>
            </div>
          </div>
        </div>

        <div class="lg:d-none">
          <div class="desktopNav">
            
            {/* <!--<div class="desktopNav__item">-->
            <!--  <a href="#">-->
            <!--    Gallery-->
            <!--  </a>-->
            <!--</div>-->
            <!--<div class="desktopNav__item">-->
            <!--  <a href="#">-->
            <!--    Contact Us-->
            <!--  </a>-->
            <!--</div>-->

            <!--<button class="button -md -accent-1 bg-accent-2 ml-40 xl:d-none">-->
            <!--  <i class="icon-phone mr-15"></i>-->
            <!--  +91 7738150248-->
            <!--</button>--> */}

          </div>

        </div>

        {/* <!-- <div class="header__right d-flex items-center h-full">
          <button class="button xl:d-none">BOOK YOUR STAY</button>
        </div> --> */}
      </div>
    </header>
    
    <div class="social-547">
      <a href="#" target="_blank">
        <div class="social-btn147 color-linkedin">
          <div class="icons8-linkedin"></div>
          
        </div>
      </a>
      <a href="#">
        <div class="social-btn147 color-instagram">
          <div class="icons8-instagram"></div>
        </div>
      </a>
      <a href="#">
        <div class="social-btn147 color-facebook">
          <div class="icons8-facebook"></div>
        </div>
      </a>
      <a href="https://api.whatsapp.com/send?phone=919137086572" target="_blank">
        <div class="social-btn147 color-whatsapp">
          <div class="icons8-whatsapp"></div>
        </div>
      </a>
      <a href="#">
        <div class="social-btn147 color-twitter">
          <div class="icons8-twitter"></div>
        </div>
      </a>
    </div>


    <div class="njs-sticky-side body-append image_button_cover placement-right now-show">
      <a href="#" target="blank_"  rel="noopener noreferrer">Download Brochure</a>
    </div>  

    </main>
  )
}





