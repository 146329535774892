import React from 'react'

export const Footer = () => {
  return (
    <main>
        <footer class="footer -type-3 -borderGap   overflow-hidden rounded-16">
      <div class="footer__bg bg-light-1"></div>

      <div class="row">
        <div class="col-lg-1"></div>
        <div class="col-lg-6">
          <div class="footer__main">
            <div class="footer__logo">
              <img src="assets/img/joy/Joy-Trails-Logo-big.png" alt="logo" className='footer-logo-box'/>
            </div>
            <div class="footer_links_new mt-40 pr-30">
              <div class="row">
                <div class="col-lg-4 col-12 mb-4">
                    <a href="/" class="mybtnAuto sofia-sans-semibold fs-16  ">Home <span class="pl-40"><i class="icon-arrow-right"></i></span></a>
                </div>
                <div class="col-lg-4 col-12 mb-4">
                    <a href="about-us" class="mybtnAuto sofia-sans-semibold fs-16  ">About Us <span class="pl-40"><i class="icon-arrow-right"></i></span></a>
                </div>
                {/* <div class="col-lg-4 col-12 mb-4">
                    <a href="services" class="mybtnAuto sofia-sans-semibold fs-16  ">Services <span class="pl-40"><i class="icon-arrow-right"></i></span></a>
                </div> */}
                <div class="col-lg-4 col-12 mb-4">
                    <a href="destination" class="mybtnAuto sofia-sans-semibold fs-16  ">Destination <span class="pl-40"><i class="icon-arrow-right"></i></span></a>
                </div>
                <div class="col-lg-4 col-12 mb-4">
                    <a href="gallery" class="mybtnAuto sofia-sans-semibold fs-16  ">Gallery <span class="pl-40"><i class="icon-arrow-right"></i></span></a>
                </div>
                <div class="col-lg-4 col-12 mb-4">
                    <a href="contact-us" class="mybtnAuto sofia-sans-semibold fs-16  ">Contact Us <span class="pl-40"><i class="icon-arrow-right"></i></span></a>
                </div>
            </div>
            </div>
            <div class="footer__content">
              <div class="row mt-4">
                <div class="col-12">
                    {/* <!-- <div class="footerNtrDiv d-flex justify-content-between align-items-center">
                        <p class="sofia-sans-regular  -theme fs-16">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eos blanditiis, fugit, consequuntur sit asperiores ea voluptatem nisi, incidunt fugiat.</p>
                    </div> --> */}
                </div>
            </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-sm-12 col-md-6 connectDiv pb-20 mt-50" id="contactDiv">

            <div class="footer-widget">
                <h4 class="footer-contact-heading  ">CONNECT WITH US</h4>
                <p class="sofia-sans-regular  ">Need more information? Contact our team for prompt assistance and support.</p>
                <div class="row">
                    <div class="col-lg-10 col-md-12 col-12">
                        
                        <div class="mb-10 d-flex justify-content-between align-items-center">
                            <div class="icon ">
                                <p class="sofia-sans-extra-condense-regular-italic text-yellow fs-24  "> Give us a Call!</p>
                            </div>
                            <div class="content">
                                <p class=" -theme sofia-sans-extra-condense-bold fs-24  ">+91 9137086572</p>
                            </div>
                        </div> 
                        
                        <div class="mb-10 d-flex justify-content-between align-items-center">
                            <div class="icon ">
                                <p class="sofia-sans-extra-condense-regular-italic text-yellow fs-24  "> Send us a Message!</p>
                            </div>
                            <div class="content">
                                <p class=" -theme sofia-sans-extra-condense-bold fs-24  ">+91 9137086572</p>
                            </div>
                        </div> 
                        
                        <div class="mb-10 d-flex justify-content-between align-items-center">
                            <div class="icon ">
                                <p class="sofia-sans-extra-condense-regular-italic text-yellow fs-24  "> Drop us an Email!</p>
                            </div>
                            <div class="content">
                                <p class=" -theme sofia-sans-extra-condense-bold fs-24  ">joytrailsindia@gmail.com</p>
                            </div>
                        </div> 
                       
                        
                        <div class="socialIcons social-menu">
                            <ul class="">
                                <li><a href="#" target="_blank"><i class="icon-facebook fab"></i></a></li>
                                <li><a href="#" target="_blank"><i class="icon-instagram fab"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                
                
            </div>
        
        </div>
      </div>



      <div class="footer__bottom bg-light-1">
        <div class="container">
          <div class="row justify-center">
            <div class="col-auto">
              <div class="text-15 text-center  ">Copyright © 2024 by Joy Trails Resorts. All rights reserved.</div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    </main>
  )
}
