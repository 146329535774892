import React, { useEffect } from 'react'
import baguetteBox from 'baguettebox.js';
import 'baguettebox.js/dist/baguetteBox.min.css';

export const AshokAstoria = () => {

    useEffect(() => {
        baguetteBox.run('.grid-gallery', {
          animation: 'slideIn',
        });
      }, []);

  return (
    <div>


    <section data-anim-wrap class="pageHero -type-2">
      <div data-anim-child="img-right cover-white delay-1" class="pageHero__bg">
        <img src="assets/img/joy/ashok-astoria.jpg" alt="image"/>
      </div>

      <div class="container">
        <div class="row justify-center">
          <div class="col-auto">
            <div class="pageHero__content text-center" data-split='lines' data-anim-child="split-lines delay-3">
              {/* <h1 class="pageHero__title text-white">Luxury Rooms</h1> */}
              {/* <p class="pageHero__text text-white">Indulge in luxury in our rooms and suites, featuring stunning views, elegant furnishings, and modern amenities.</p> */}
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="layout-pt-md layout-pb-lg">
      <div class="container">
        <div class="row y-gap-40 justify-between">
          <div class="col-xl-12 col-lg-12">
            
            <h2 class="text-40 font-size-30-moible">About Ashok Astoria</h2>
            {/* <div class="d-flex x-gap-50 md:x-gap-20 pt-40 md:pt-30"> */}

              {/* <div class="d-flex items-center text-accent-1">
                <i class="icon-size text-20 mr-10"></i>
                30M2
              </div>

              <div class="d-flex items-center text-accent-1">
                <i class="icon-guest text-20 mr-10"></i>
                2 GUEST
              </div> */}

              {/* <div class="d-flex items-center text-accent-1">
                <i class="icon-bed-2 text-20 mr-10"></i>
                3 Rooms
              </div> */}

              {/* <div class="d-flex items-center text-accent-1">
                <i class="icon-bath text-20 mr-10"></i>
                1 BATH
              </div> */}

            {/* </div> */}

            <div class="line -horizontal bg-border mt-30 mb-50"></div>

            <p  className='text-justify'>
            We all will love a break from the Hustle & Bustle of our  daily lives.  The Town Home @ Ashok Astoria, on the famous Gangapur road at Nasik is the perfect place for this. Ashok Astoria Town Home is a premium 3BHK apartment with separate servant room. It has very nice ambience with all modern amenities to enjoy & relax. Environment is free from pollution with beautiful greenery all around the project. The complex itself has a lot of things to do & enjoy your stay there.
              {/* <br/><br/> */}
              {/* Inspired by the legendary emperor Ashok Astoriaa, known for his vision and strength, our resort embodies the same spirit of excellence and grandeur. We aim to provide guests with an extraordinary experience that reflects the cultural richness, natural beauty, and welcoming warmth of our region. */}
              {/* <br/><br/> */}
              {/* At Ashok Astoria Resort, we believe that every stay is an opportunity to create lasting memories. Whether you're here to relax by the sea or explore new adventures, we invite you to experience the magic of the coast with us. */}
            </p>


           
            


            <div class="line -horizontal bg-border mt-50 mb-50"></div>
            <h2 class="text-30">What's included in this Resorts?</h2>
            <div class="row x-gap-50 y-gap-20 pt-40">
              <div class="col-sm-6">
                <ul class="ulList -type-1">
                  <li>CCTV</li>
                  <li>Fire Extinguishers</li>
                  <li>Electrical Chargers</li>
                  <li>Seating Area</li>
                </ul>
              </div>

              <div class="col-sm-6">
                <ul class="ulList -type-1">
                  <li>Restaurant</li>
                  <li>Kids Play Area</li>
                  <li>Lawn</li>
                  <li>Reception</li>
                </ul>
              </div>
            </div>


            {/* <div class="line -horizontal bg-border mt-50 mb-50"></div>
            <h2 class="text-30">Room Facilities</h2>
            <div className='row'>
              <div className='col-lg-6'>
                <ul class="ulList -type-1 pt-40">
                  <li>Indoor Games</li>
                  <li>Refrigerator</li>
                  <li>Attached Bathroom</li>
                </ul>
              </div>
              <div className='col-lg-6'>
              <ul class="ulList -type-1 pt-40">
                <li>Gym</li>
                <li>Swimming Pool</li>
                <li>Power Backup</li>
              </ul>
              </div>
            </div> */}
          </div>

          <div class="col-xl-4 col-lg-5 d-none">
            <div class="sidebar -rooms-single px-40 py-40 md:px-30 md:py-30 border-1 shadow-1">
              <h3 class="text-30 mb-30">Book Your Room</h3>

              <div class="row y-gap-30">
                <div class="col-12">

                  <div class="searchForm -type-1 -dropdown-single">
                    <div class="searchForm__form">
                      <div class="searchFormItem js-select-control js-form-dd js-calendar">
                        <div class="searchFormItem__button" data-x-click="calendar">
                          <div>
                            <span class="js-first-date">Check In - Check Out</span>
                            <span class="js-last-date"></span>
                          </div>
                          <i class="icon-chevron-down ml-40"></i>
                        </div>


                        <div class="searchFormItemDropdown -calendar" data-x="calendar" data-x-toggle="is-active">
                          <div class="searchFormItemDropdown__container">

                            <div class="searchMenu-date -searchForm js-form-dd js-calendar-el">
                              <div class="searchMenu-date__field shadow-2" data-x-dd="searchMenu-date" data-x-dd-toggle="-is-active">
                                <div class="rounded-4">
                                  <div class="elCalendar js-calendar-el-calendar"></div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="col-12">

                  <div class="searchForm -type-1 -dropdown-single">
                    <div class="searchForm__form">
                      <div class="searchFormItem js-select-control js-form-dd">
                        <div class="searchFormItem__button" data-x-click="rooms">
                          <div class="js-select-control-chosen">Rooms</div>
                          <i class="icon-chevron-down ml-40"></i>
                        </div>
                        <div class="searchFormItemDropdown -counter" data-x="rooms" data-x-toggle="is-active">
                          <div class="searchFormItemDropdown__container">
                            <div class="searchFormItemDropdown__list">

                              <div class="searchFormItemDropdown__item js-select-control-counter">
                                <div class="fw-500 js-search-title">Rooms</div>
                                <div class="d-flex fw-500 js-search-counter">
                                  <div class="cursor-pointer size-40 flex-center js-remove">
                                    <i class="custom-icon-minus">
                                      <span></span>
                                    </i>
                                  </div>

                                  <div class="size-40 flex-center js-number">0</div>

                                  <div class="cursor-pointer size-40 flex-center js-add">
                                    <i class="custom-icon-plus">
                                      <span></span>
                                      <span></span>
                                    </i>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                </div>
                <div class="col-12">

                  <div class="searchForm -type-1 -dropdown-single">
                    <div class="searchForm__form">
                      <div class="searchFormItem js-select-control js-form-dd">
                        <div class="searchFormItem__button" data-x-click="guests-2">
                          <div class="js-select-control-chosen">Adults (18+ Years)</div>
                          <i class="icon-chevron-down ml-40"></i>
                        </div>
                        <div class="searchFormItemDropdown -counter" data-x="guests-2" data-x-toggle="is-active">
                          <div class="searchFormItemDropdown__container">
                            <div class="searchFormItemDropdown__list">

                              <div class="searchFormItemDropdown__item js-select-control-counter">
                                <div class="fw-500 js-search-title">Adults</div>
                                <div class="d-flex fw-500 js-search-counter">
                                  <div class="cursor-pointer size-40 flex-center js-remove">
                                    <i class="custom-icon-minus">
                                      <span></span>
                                    </i>
                                  </div>

                                  <div class="size-40 flex-center js-number">0</div>

                                  <div class="cursor-pointer size-40 flex-center js-add">
                                    <i class="custom-icon-plus">
                                      <span></span>
                                      <span></span>
                                    </i>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                </div>
                <div class="col-12">

                  <div class="searchForm -type-1 -dropdown-single">
                    <div class="searchForm__form">
                      <div class="searchFormItem js-select-control js-form-dd">
                        <div class="searchFormItem__button" data-x-click="guests-3">
                          <div class="js-select-control-chosen">Children (0-6 Years)</div>
                          <i class="icon-chevron-down ml-40"></i>
                        </div>
                        <div class="searchFormItemDropdown -counter" data-x="guests-3" data-x-toggle="is-active">
                          <div class="searchFormItemDropdown__container">
                            <div class="searchFormItemDropdown__list">

                              <div class="searchFormItemDropdown__item js-select-control-counter">
                                <div class="fw-500 js-search-title">Children</div>
                                <div class="d-flex fw-500 js-search-counter">
                                  <div class="cursor-pointer size-40 flex-center js-remove">
                                    <i class="custom-icon-minus">
                                      <span></span>
                                    </i>
                                  </div>

                                  <div class="size-40 flex-center js-number">0</div>

                                  <div class="cursor-pointer size-40 flex-center js-add">
                                    <i class="custom-icon-plus">
                                      <span></span>
                                      <span></span>
                                    </i>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <h3 class="text-30 mb-20 mt-40">Extra Services</h3>


              <div class="d-flex items-center">
                <div class="form-checkbox ">
                  <input type="checkbox" name="name"/>
                  <div class="form-checkbox__mark">
                    <div class="form-checkbox__icon">
                      <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.29082 0.971021C9.01235 0.692189 8.56018 0.692365 8.28134 0.971021L3.73802 5.51452L1.71871 3.49523C1.43988 3.21639 0.987896 3.21639 0.709063 3.49523C0.430231 3.77406 0.430231 4.22604 0.709063 4.50487L3.23309 7.0289C3.37242 7.16823 3.55512 7.23807 3.73783 7.23807C3.92054 7.23807 4.10341 7.16841 4.24274 7.0289L9.29082 1.98065C9.56965 1.70201 9.56965 1.24984 9.29082 0.971021Z" fill="white" />
                      </svg>
                    </div>
                  </div>
                </div>

                <div class="ml-10">Service per booking <span class="fw-500">$60</span></div>

              </div>


              <div class="d-flex mt-15">
                <div class="form-checkbox ">
                  <input type="checkbox" name="name"/>
                  <div class="form-checkbox__mark">
                    <div class="form-checkbox__icon">
                      <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.29082 0.971021C9.01235 0.692189 8.56018 0.692365 8.28134 0.971021L3.73802 5.51452L1.71871 3.49523C1.43988 3.21639 0.987896 3.21639 0.709063 3.49523C0.430231 3.77406 0.430231 4.22604 0.709063 4.50487L3.23309 7.0289C3.37242 7.16823 3.55512 7.23807 3.73783 7.23807C3.92054 7.23807 4.10341 7.16841 4.24274 7.0289L9.29082 1.98065C9.56965 1.70201 9.56965 1.24984 9.29082 0.971021Z" fill="white" />
                      </svg>
                    </div>
                  </div>
                </div>

                <div class="ml-10">Service per person Adult : <span class="fw-500">$20</span></div>

              </div>


              <button class="button -md bg-accent-2 -dark-1 w-1/1 mt-40">BOOK YOUR STAY NOW</button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section data-anim-wrap class="relative layout-pt-lg layout-pb-lg">
        <div
          data-anim-child="slide-up delay-2"
          class="sectionBg -mx-60 bg-light-1 rounded-16"
        ></div>

        <div class="container">
          <div class="row justify-center text-center">
            <div data-anim-child="slide-up delay-4" class="col-auto">
              <div class="text-15 uppercase mb-20 sm:mb-10">OUR SERVICES</div>
              <h2 class="text-40 md:text-30">Resort Facilities</h2>
            </div>
          </div>

          <div class="row y-gap-30 justify-between pt-60 sm:pt-50">
            <div
              data-anim-child="slide-up delay-3"
              class="col-lg-auto col-md-4 col-6"
            >
              <div class="iconCard -type-1 -hover-1">
                <div class="iconCard__icon text-50">
                  <div class="iconCard__icon__circle"></div>
                  <i class="icon-wifi"></i>
                </div>
                <h4 class="text-24 text-center mt-20">Wifi &amp; Internet</h4>
              </div>
            </div>

            <div
              data-anim-child="slide-up delay-4"
              class="col-lg-auto col-md-4 col-6"
            >
              <div class="iconCard -type-1 -hover-1">
                <div class="iconCard__icon text-50">
                  <div class="iconCard__icon__circle"></div>
                  <i class="icon-bus"></i>
                </div>
                <h4 class="text-24 text-center mt-20">Parking</h4>
              </div>
            </div>

            <div
              data-anim-child="slide-up delay-5"
              class="col-lg-auto col-md-4 col-6"
            >
              <div class="iconCard -type-1 -hover-1">
                <div class="iconCard__icon text-50">
                  <div class="iconCard__icon__circle"></div>
                  <i class="icon-tv"></i>
                </div>
                <h4 class="text-24 text-center mt-20">TV</h4>
              </div>
            </div>

            <div
              data-anim-child="slide-up delay-6"
              class="col-lg-auto col-md-4 col-6"
            >
              <div class="iconCard -type-1 -hover-1">
                <div class="iconCard__icon text-50">
                  <div class="iconCard__icon__circle"></div>
                  <i class="icon-bed"></i>
                </div>
                <h4 class="text-24 text-center mt-20">Air Conditioning</h4>
              </div>
            </div>

            <div
              data-anim-child="slide-up delay-7"
              class="col-lg-auto col-md-4 col-6"
            >
              <div class="iconCard -type-1 -hover-1">
                <div class="iconCard__icon text-50">
                  <div class="iconCard__icon__circle"></div>
                  <i class="icon-laundry"></i>
                </div>
                <h4 class="text-24 text-center mt-20">Washing machine</h4>
              </div>
            </div>

            <div
              data-anim-child="slide-up delay-8"
              class="col-lg-auto col-md-4 col-6"
            >
              <div class="iconCard -type-1 -hover-1">
                <div class="iconCard__icon text-50">
                  <div class="iconCard__icon__circle"></div>
                  <i class="icon-housekeeper"></i>
                </div>
                <h4 class="text-24 text-center mt-20">Housekeeper Services</h4>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="relative layout-pt-lg layout-pb-lg grid-gallery">
        
        <div data-anim-wrap="true" class="container animated">
        <div class="row justify-center text-center mb-30">
            <div data-anim-child="slide-up delay-4" class="col-auto">
              <div class="text-15 uppercase mb-20 sm:mb-10">OUR Gallery</div>
              <h2 class="text-40 md:text-30">Gallery</h2>
            </div>
          </div>
          <div class="row y-gap-40 items-center">
            <div class="col-xl-12">
              <div class="pl-40 lg:pl-0">
                <div
                  class="relative overflow-hidden js-section-slider swiper-initialized swiper-horizontal swiper-pointer-events swiper-autoheight swiper-watch-progress swiper-backface-hidden"
                  data-gap="20"
                  data-slider-cols="xl-3 lg-3 md-2 sm-1 base-1"
                  data-nav-prev="js-slider3-prev"
                  data-nav-next="js-slider3-next"
                >
                  <div
                    class="swiper-wrapper"
                    id="swiper-wrapper-faa753c3ebeea5109"
                    aria-live="polite"
                  >
                    <div
                      class="swiper-slide swiper-slide-visible swiper-slide-active"
                      role="group"
                      aria-label="1 / 6"
                    >
                      <div
                        data-anim-child="img-right cover-accent-1 delay-2"
                        class="is-in-view"
                      >
                        <a href="#" class="baseCard -type-3">
                          <div class="baseCard__image ratio ratio-33:45">
                            <a href="assets/img/joy/ashok/ashok-astoria-images-1.jpg">
                              <img
                                src="assets/img/joy/ashok/ashok-astoria-images-1.jpg"
                                alt="image"
                                class="img-ratio rounded-16"
                              />
                            </a>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div
                      class="swiper-slide swiper-slide-visible swiper-slide-next"
                      role="group"
                      aria-label="2 / 6"
                    >
                      <div
                        data-anim-child="img-right cover-accent-1 delay-4"
                        class="is-in-view"
                      >
                        <a href="#" class="baseCard -type-3">
                          <div class="baseCard__image ratio ratio-33:45">
                            <a href="assets/img/joy/ashok/ashok-astoria-images-2.jpg">
                              <img
                                src="assets/img/joy/ashok/ashok-astoria-images-2.jpg"
                                alt="image"
                                class="img-ratio rounded-16"
                              />
                            </a>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div
                      class="swiper-slide swiper-slide-visible"
                      role="group"
                      aria-label="3 / 6"
                    >
                      <div
                        data-anim-child="img-right cover-accent-1 delay-6"
                        class="is-in-view"
                      >
                        <a href="#" class="baseCard -type-3">
                          <div class="baseCard__image ratio ratio-33:45">
                            <a href="assets/img/joy/ashok/ashok-astoria-images-3.jpg">
                              <img
                                src="assets/img/joy/ashok/ashok-astoria-images-3.jpg"
                                alt="image"
                                class="img-ratio rounded-16"
                              />
                            </a>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class="swiper-slide" role="group" aria-label="4 / 6">
                      <div
                        data-anim-child="img-right cover-accent-1 delay-8"
                        class="is-in-view"
                      >
                        <a href="#" class="baseCard -type-3">
                          <div class="baseCard__image ratio ratio-33:45">
                            <a href="assets/img/joy/ashok/ashok-astoria-images-4.jpg">
                              <img
                                src="assets/img/joy/ashok/ashok-astoria-images-4.jpg"
                                alt="image"
                                class="img-ratio rounded-16"
                              />
                            </a>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class="swiper-slide" role="group" aria-label="5 / 6">
                      <div
                        data-anim-child="img-right cover-accent-1 delay-10"
                        class="is-in-view"
                      >
                        <a href="#" class="baseCard -type-3">
                          <div class="baseCard__image ratio ratio-33:45">
                            <a href="assets/img/joy/ashok/ashok-astoria-images-5.jpg">
                              <img
                                src="assets/img/joy/ashok/ashok-astoria-images-5.jpg"
                                alt="image"
                                class="img-ratio rounded-16"
                              />
                            </a>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class="swiper-slide" role="group" aria-label="6 / 6">
                      <div
                        data-anim-child="img-right cover-accent-1 delay-12"
                        class="is-in-view"
                      >
                        <a href="#" class="baseCard -type-3">
                          <div class="baseCard__image ratio ratio-33:45">
                            <a href="assets/img/joy/ashok/ashok-astoria-images-7.jpg">
                              <img
                                src="assets/img/joy/ashok/ashok-astoria-images-7.jpg"
                                alt="image"
                                class="img-ratio rounded-16"
                              />
                            </a>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <span
                    class="swiper-notification"
                    aria-live="assertive"
                    aria-atomic="true"
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
