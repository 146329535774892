import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Header } from './Components/Header';
import { AllRoutes } from './Routes/AllRoutes';
import { Footer } from './Components/Footer';
import { BrowserRouter } from 'react-router-dom';

function App() {
  return (
    <div>
      <BrowserRouter>
      <Header/>
      <AllRoutes/>
      <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
