import React from 'react'

export const Services = () => {
  return (
    <div>
         <section class="layout-pt-lg layout-pb-lg mt-60">
      <div class="container">
        <div data-anim-wrap class="row justify-center text-center">
          <div data-anim-child="slide-up delay-3" class="col-xl-7 col-lg-8">
            <div class="text-15 uppercase mb-20 sm:mb-10">LUXURY RESORT</div>
            <h2 class="text-40 md:text-30">Exceptional gastronomy in beautiful spaces</h2>
          </div>
        </div>


        <div data-anim-wrap class="row y-gap-30 items-center pt-60 sm:pt-50">
          <div class="col-lg-1"></div>
          <div data-anim-child="img-right cover-white delay-1" class="col-md-5">
            <div class="ratio ratio-1:1">
              <img src="assets/img/joy/image-1.png" alt="image" class="img-ratio rounded-16"/>
            </div>
          </div>

          <div data-anim-child="slide-up delay-3" class="col-xl-3 col-lg-4 col-md-5 offset-md-1">
            <i class="d-block icon-restaurant text-30 mb-30"></i>
            <h3 class="text-40 md:text-30 lh-065">Garden</h3>
            <p class="mt-30">A wonderful little place that serves up tasty food at affordable prices.</p>
          </div>
        </div>

        <div data-anim-wrap class="row y-gap-30 items-center pt-80 sm:pt-50">
          <div class="col-lg-1"></div>
          
          <div data-anim-child="slide-up delay-3" class="col-xl-3 col-lg-4 col-md-5 offset-md-1">
            <i class="d-block icon-restaurant text-30 mb-30"></i>
            <h3 class="text-40 md:text-30 lh-065">Outside Sitting</h3>
            <p class="mt-30">A wonderful little place that serves up tasty food at affordable prices.</p>
          </div>
          <div class="col-lg-1"></div>
          <div data-anim-child="img-right cover-white delay-1" class="col-md-5">
            <div class="ratio ratio-1:1">
              <img src="assets/img/joy/image-2.png" alt="image" class="img-ratio rounded-16"/>
            </div>
          </div>
        </div>

        <div data-anim-wrap class="row y-gap-30 items-center pt-80 sm:pt-50">
          <div class="col-lg-1"></div>
          <div data-anim-child="img-right cover-white delay-1" class="col-md-5">
            <div class="ratio ratio-1:1">
              <img src="assets/img/joy/image-3.png" alt="image" class="img-ratio rounded-16"/>
            </div>
          </div>

          <div data-anim-child="slide-up delay-3" class="col-xl-3 col-lg-4 col-md-5 offset-md-1">
            <i class="d-block icon-restaurant text-30 mb-30"></i>
            <h3 class="text-40 md:text-30 lh-065">Temple</h3>
            <p class="mt-30">A wonderful little place that serves up tasty food at affordable prices.</p>
          </div>
        </div>

      </div>
    </section>
    </div>
  )
}
