import React from 'react'

export const AboutUs = () => {
  return (
    <div>
         <section class="layout-pt-lg layout-pb-lg mt-60">
      <div data-anim-wrap class="container">
        <div class="row y-gap-50 items-center justify-between">
          <div class="col-lg-5 col-md-9">
            <div data-anim-child="slide-up delay-1" class="text-15 uppercase mb-20 sm:mb-10 mobile-text-center">About</div>
            <h2 data-anim-child="slide-up delay-2" class="text-40 md:text-30 capitalize mobile-text-center">Samudragupt Lifestyle Retreat</h2>
            <p data-anim-child="slide-up delay-3" class="lh-18 lg:pr-0 mt-40 md:mt-30 text-justify">
            We Joy Trails India is a team of first generation entrepreneurs who are desirous to provide you the best stay options at reasonable rates at different beautiful locations so that you enjoy your vacations with your loved ones. Everyone has a wish list of having a Dream Home near Beach, River, Lake, Valley, Mountain etc. However there are very few lucky persons to have all these of their own.
              <br/>
              Hence Joy Trails India has also come up with a unique idea of Club Membership for Vacations at all such locations, where everyone will love to stay, relax & enjoy.
              <br/>
              Join our Club Membership and you are welcome for family vacations at our beautiful stay options at affordable price.
              <br/>
              Also you are welcome to book independent room for your family and enjoy the luxury stay at our stay options available.
            </p>
            
          </div>

          <div class="col-lg-6">
            <div data-anim-child="img-right cover-white delay-2">
              <div class="imageGrid">
                
                <img src="assets/img/joy/joy-tread-aboutus-2.png" alt="image"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>  


    <section data-anim-wrap class="relative layout-pt-lg layout-pb-lg">
      <div data-anim-child="slide-up delay-2" class="sectionBg -mx-60 bg-light-1 rounded-16"></div>

      <div class="container">
        <div class="row justify-center text-center">
          <div data-anim-child="slide-up delay-4" class="col-auto">
            <div class="text-15 uppercase mb-20 sm:mb-10">OUR SERVICES</div>
            <h2 class="text-40 md:text-30">Resort Facilities</h2>
          </div>
        </div>

        <div class="row y-gap-30 justify-between pt-60 sm:pt-50">

          <div data-anim-child="slide-up delay-3" class="col-lg-auto col-md-4 col-6">
            <div class="iconCard -type-1 -hover-1">
              <div class="iconCard__icon text-50">
                <div class="iconCard__icon__circle"></div>
                <i class="icon-wifi"></i>
              </div>
              <h4 class="text-24 text-center mt-20">Wifi &amp; Internet</h4>
            </div>
          </div>

          <div data-anim-child="slide-up delay-4" class="col-lg-auto col-md-4 col-6">
            <div class="iconCard -type-1 -hover-1">
              <div class="iconCard__icon text-50">
                <div class="iconCard__icon__circle"></div>
                <i class="icon-bus"></i>
              </div>
              <h4 class="text-24 text-center mt-20">Parking</h4>
            </div>
          </div>

          <div data-anim-child="slide-up delay-5" class="col-lg-auto col-md-4 col-6">
            <div class="iconCard -type-1 -hover-1">
              <div class="iconCard__icon text-50">
                <div class="iconCard__icon__circle"></div>
                <i class="icon-tv"></i>
              </div>
              <h4 class="text-24 text-center mt-20">TV</h4>
            </div>
          </div>

          <div data-anim-child="slide-up delay-6" class="col-lg-auto col-md-4 col-6">
            <div class="iconCard -type-1 -hover-1">
              <div class="iconCard__icon text-50">
                <div class="iconCard__icon__circle"></div>
                <i class="icon-bed"></i>
              </div>
              <h4 class="text-24 text-center mt-20">Air Conditioning</h4>
            </div>
          </div>

          <div data-anim-child="slide-up delay-7" class="col-lg-auto col-md-4 col-6">
            <div class="iconCard -type-1 -hover-1">
              <div class="iconCard__icon text-50">
                <div class="iconCard__icon__circle"></div>
                <i class="icon-laundry"></i>
              </div>
              <h4 class="text-24 text-center mt-20">Washing machine</h4>
            </div>
          </div>

          <div data-anim-child="slide-up delay-8" class="col-lg-auto col-md-4 col-6">
            <div class="iconCard -type-1 -hover-1">
              <div class="iconCard__icon text-50">
                <div class="iconCard__icon__circle"></div>
                <i class="icon-housekeeper"></i>
              </div>
              <h4 class="text-24 text-center mt-20">Housekeeper Services</h4>
            </div>
          </div>

        </div>
      </div>
    </section>

    <section class="relative layout-pt-lg layout-pb-lg sm:pb-0 sm:pt-0 mt-60 mb-60">
      <div data-anim-wrap class="container">
        <div class="sideImages -type-1">
          <div data-anim-child="img-right cover-white delay-5">
            <img src="assets/img/joy/joy-125.png" alt="image"/>
          </div>
          <div data-anim-child="img-right cover-white delay-5">
            <img src="assets/img/joy/joy-126.png" alt="image"/>
          </div>
        </div>

        <div class="row justify-center text-center">
          <div class="col-xxl-5 col-xl-6">
            <div data-split='lines' data-anim-child="split-lines delay-2">
              <div class="text-15 uppercase mb-30 sm:mb-10">MEETING & EVENTS</div>
              <h2 class="text-40 lg:text-40 md:text-30">
                A Warm, Exquisite,<br class="lg:d-none"/>
                Practical And<br class="lg:d-none"/>
                Urban Space.
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>

    </div>
  )
}
