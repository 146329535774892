import React ,{useEffect} from 'react'
import baguetteBox from 'baguettebox.js';
import 'baguettebox.js/dist/baguetteBox.min.css';

export const Gallery = () => {

  useEffect(() => {
    baguetteBox.run('.grid-gallery', {
      animation: 'slideIn',
    });
  }, []);

  return (
    <div>
    <section class="layout-pt-lg layout-pb-lg mt-60 grid-gallery">
      <div data-anim-wrap class="container">
        <div class="row justify-center text-center">
          <div class="col-auto" data-split='lines' data-anim-child="split-lines delay-2">
            <div class="text-15 uppercase mb-10 sm:mb-10">Our</div>
            <h2 class="text-40 md:text-30">Gallery</h2>
          </div>
        </div>

        <div class="overflow-hidden pt-60 sm:pt-50" data-gap="30" data-slider-cols="xl-3 lg-3 md-2 sm-1 base-1" data-pagination="js-slider2-pagination">
          <div class="row">

            <div class="col-lg-4">
              <a href="assets/img/joy/joy-4.jpg" class="baseCard -type-1 -padding-lg" data-anim-child="img-right cover-white delay-2">
                <div class="baseCard__image ratio ratio-45:54">
                  <img src="assets/img/joy/joy-4.jpg" alt="image" class="img-ratio"/>
                </div>
              </a>
            </div>

            <div class="col-lg-4">
              <a href="assets/img/joy/image-2.png" class="baseCard -type-1 -padding-lg" data-anim-child="img-right cover-white delay-4">
                <div class="baseCard__image ratio ratio-45:54">
                  <img src="assets/img/joy/image-2.png" alt="image" class="img-ratio"/>
                </div>
              </a>
            </div>

            <div class="col-lg-4">
              <a href="assets/img/joy/image-3.png" class="baseCard -type-1 -padding-lg" data-anim-child="img-right cover-white delay-6">
                <div class="baseCard__image ratio ratio-45:54">
                  <img src="assets/img/joy/image-3.png" alt="image" class="img-ratio"/>
                </div>
              </a>
            </div>

            <div class="col-lg-4">
              <a href="assets/img/joy/image-1.png" class="baseCard -type-1 -padding-lg" data-anim-child="img-right cover-white delay-8">
                <div class="baseCard__image ratio ratio-45:54">
                  <img src="assets/img/joy/image-1.png" alt="image" class="img-ratio"/>
                </div>
              </a>
            </div>

            <div class="col-lg-4">
              <a href="assets/img/joy/joy-1.jpg" class="baseCard -type-1 -padding-lg" data-anim-child="img-right cover-white delay-10">
                <div class="baseCard__image ratio ratio-45:54">
                  <img src="assets/img/joy/joy-1.jpg" alt="image" class="img-ratio"/>
                </div>
              </a>
            </div>

            <div class="col-lg-4">
              <a href="assets/img/joy/joy-tread-aboutus.png" class="baseCard -type-1 -padding-lg" data-anim-child="img-right cover-white delay-12">
                <div class="baseCard__image ratio ratio-45:54">
                  <img src="assets/img/joy/joy-tread-aboutus.png" alt="image" class="img-ratio"/>
                </div>
              </a>
            </div>

            <div class="col-lg-4">
              <a href="assets/img/joy/ashok/ashok-astoria-images-1.jpg" class="baseCard -type-1 -padding-lg" data-anim-child="img-right cover-white delay-12">
                <div class="baseCard__image ratio ratio-45:54">
                  <img src="assets/img/joy/ashok/ashok-astoria-images-1.jpg" alt="image" class="img-ratio"/>
                </div>
              </a>
            </div>

            <div class="col-lg-4">
              <a href="assets/img/joy/ashok/ashok-astoria-images-2.jpg" class="baseCard -type-1 -padding-lg" data-anim-child="img-right cover-white delay-12">
                <div class="baseCard__image ratio ratio-45:54">
                  <img src="assets/img/joy/ashok/ashok-astoria-images-2.jpg" alt="image" class="img-ratio"/>
                </div>
              </a>
            </div>

            <div class="col-lg-4">
              <a href="assets/img/joy/ashok/ashok-astoria-images-3.jpg" class="baseCard -type-1 -padding-lg" data-anim-child="img-right cover-white delay-12">
                <div class="baseCard__image ratio ratio-45:54">
                  <img src="assets/img/joy/ashok/ashok-astoria-images-3.jpg" alt="image" class="img-ratio"/>
                </div>
              </a>
            </div>

            <div class="col-lg-4">
              <a href="assets/img/joy/ashok/ashok-astoria-images-4.jpg" class="baseCard -type-1 -padding-lg" data-anim-child="img-right cover-white delay-12">
                <div class="baseCard__image ratio ratio-45:54">
                  <img src="assets/img/joy/ashok/ashok-astoria-images-4.jpg" alt="image" class="img-ratio"/>
                </div>
              </a>
            </div>

            <div class="col-lg-4">
              <a href="assets/img/joy/ashok/ashok-astoria-images-5.jpg" class="baseCard -type-1 -padding-lg" data-anim-child="img-right cover-white delay-12">
                <div class="baseCard__image ratio ratio-45:54">
                  <img src="assets/img/joy/ashok/ashok-astoria-images-5.jpg" alt="image" class="img-ratio"/>
                </div>
              </a>
            </div>

            






          </div>
        </div>
      </div>
    </section>
    </div>
  )
}

